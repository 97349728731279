








































































































import Vue from 'vue';
import firebase from 'firebase/app';
import 'firebase/firestore';
import { db } from '@/main';
import algoliasearch from 'algoliasearch';
import constants from '../constants/constants';
import { Position } from '@/models/position.model';
import { Practice } from '@/models/practice.model';
import router from '@/router';

export default Vue.extend({
  name: 'Positions',
  data() {
    return {
      selectedPosition: '',
      search: null,
      snackbar: false,
      snackbarColor: '',
      snackbarText: '',
      footerProps: { 'items-per-page-options': [20, 50, 100, 200, 500, 1000] },
      headers: [
        {
          value: 'internalReference',
          text: 'Your Reference',
          sortable: true
        },
        { value: 'title', text: 'Title', sortable: true },
        { value: 'contractType', text: 'Contract Type', sortable: true },
        { value: 'isLive', text: 'Live', sortable: true }
      ],
      options: {} as any,
      page: 1,
      totalRows: 1,
      perPage: { value: 50, text: '50 per page' },
      position: {} as Position,
      positions: [] as Position[],
      client: algoliasearch(
        constants.ALGOLIA.APP_ID,
        constants.ALGOLIA.SEARCH_KEY
      ),
      topVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      bottomVisible: {} as firebase.firestore.QueryDocumentSnapshot<
        firebase.firestore.DocumentData
      >,
      searchResults: [],
      isBusy: false,
      isSearchLoading: false,
      tags: [],
      previousPageNumber: 1,
      dataListener: (null as null | {}) as () => void,
      selectedPositionTypes: []
    };
  },
  methods: {
    addPosition() {
      router.push({
        name: 'EditPosition',
        query: {
          positionUid: null
        }
      });
    },
    saveData(position: Position) {
      db.collection('positions')
        .doc(position.uid)
        .set(position, { merge: true })
        .then(() => {
          this.showSnackbar('Successfuly updated position.', 'success');
          this.savePositionReference();
          this.initData();
        })
        .catch((error: Error) => {
          console.log(error);
          this.showSnackbar(error.message, 'error');
        });
    },
    deleteData() {
      this.$confirm(
        `Are you sure you want to delete the selected ${
          this.selectedPositionTypes.length
        } ${this.selectedPositionTypes.length > 1 ? 'items' : 'item'}`
      ).then(res => {
        if (res) {
          const batch = db.batch();
          this.selectedPositionTypes.forEach((position: Position) => {
            batch.delete(db.collection('positions').doc(position.uid));
          });
          batch.commit().then(() => {
            this.selectedPositionTypes = [];
            this.initData();
          });
        }
      });
    },
    setLive(live: boolean) {
      this.$confirm(
        `Are you sure you want to set the selected ${
          this.selectedPositionTypes.length
        } ${this.selectedPositionTypes.length > 1 ? 'items' : 'item'} to ${
          live ? 'live' : 'paused'
        }`
      ).then(res => {
        if (res) {
          const batch = db.batch();
          this.selectedPositionTypes.forEach((position: Position) => {
            batch.set(
              db.collection('positions').doc(position.uid),
              { isLive: live },
              { merge: true }
            );
          });
          batch.commit().then(() => {
            this.selectedPositionTypes = [];
            this.initData();
          });
        }
      });
    },
    async savePositionReference() {
      try {
        const practice = await db
          .collection('practices')
          .doc(this.$store.state.currentUser.activePractice.uid)
          .get()
          .then(doc => doc.data() as Practice);
        if (!practice.positionUids) {
          practice.positionUids = [];
        }
        practice.positionUids.push(this.position.uid);
        db.collection('practices')
          .doc(practice.uid)
          .set(practice, { merge: true });
      } catch (error) {
        console.error(error);
      }
    },
    initData() {
      this.getTotalPositionCount();
      this.getData();
    },
    getTotalPositionCount() {
      const filter = `practiceUid:${this.$store.state.currentUser.activePractice.uid}`;
      this.algoliaClient
        .search('', {
          filters: filter
        })
        .then(({ nbHits }: any) => {
          this.totalRows = nbHits > 0 ? nbHits : 1;
        })
        .catch((err: any) => {
          console.log(err);
          return null;
        });
    },
    getSearchResults(value: string) {
      this.isSearchLoading = true;
      this.algoliaClient
        .search(value, {
          filters: `practiceUid:${this.$store.state.currentUser.activePractice.uid}`,
          hitsPerPage: 5
        })
        .then(({ hits }: any) => {
          this.isSearchLoading = false;
          this.searchResults = hits.map((hit: any) => {
            const item = { ...hit };
            delete item.objectID;
            const position = item as Position;
            return {
              value: position.uid,
              text: position.internalReference ?? position.title
            };
          });
        })
        .catch((err: any) => {
          this.isSearchLoading = false;
          console.log(err);
          return null;
        });
    },
    getData() {
      const { sortBy, sortDesc, page, itemsPerPage } = this.options;
      this.isBusy = true;
      let query = this.mainQuery;
      let order = 'asc' as 'asc' | 'desc';
      let sort = 'internalReference';
      if (sortBy && sortBy.length > 0) {
        sort = sortBy[0];
      }
      if (sortDesc !== undefined && sortDesc.length > 0) {
        order = sortDesc[0] ? 'desc' : 'asc';
      }
      query = query.orderBy(sort, order);
      const pageDiff = (page ?? 1) - this.previousPageNumber;
      this.previousPageNumber = page ?? 1;
      switch (pageDiff) {
        case 0:
          query = query.limit(itemsPerPage ?? this.perPage.value);
          break;
        case 1:
          query = query
            .startAfter(this.bottomVisible)
            .limit(itemsPerPage ?? this.perPage.value);
          break;
        case -1:
          query = query
            .endBefore(this.topVisible)
            .limitToLast(itemsPerPage ?? this.perPage.value);
          break;
        default:
          query = query.limit(itemsPerPage ?? this.perPage.value);
      }
      if (this.dataListener) {
        this.dataListener();
      }
      this.dataListener = query.onSnapshot(
        (snapshot: firebase.firestore.QuerySnapshot) => {
          this.isBusy = false;
          if (snapshot.docs.length > 0) {
            this.positions = snapshot.docs.map(
              value => value.data() as Position
            );
            this.topVisible = snapshot.docs[0];
            this.bottomVisible = snapshot.docs[snapshot.docs.length - 1];
          }
        },
        (error: Error) => {
          console.error(error);
        }
      );
    },
    selectedRow(item: Position) {
      router.push({
        name: 'EditPosition',
        query: {
          positionUid: item.uid
        }
      });
    },
    showSnackbar(text: string, color: string) {
      this.snackbarText = text;
      this.snackbarColor = color;
      this.snackbar = true;
    }
  },
  watch: {
    search(val) {
      if (val) {
        this.getSearchResults(val);
      } else {
        this.searchResults = [];
      }
    },
    selectedPosition() {
      if (this.selectedPosition) {
        this.position = this.positions.find(
          value => value.uid === this.selectedPosition
        ) as Position;
        this.selectedPosition = '';
        router.push({
          name: 'EditPosition',
          query: {
            positionUid: this.position.uid
          }
        });
      }
    },
    options: {
      handler() {
        this.getData();
      },
      deep: true
    }
  },
  created() {
    this.initData();
  },
  beforeDestroy() {
    if (this.dataListener) {
      this.dataListener();
    }
  },
  computed: {
    algoliaClient(): any {
      return this.client.initIndex(constants.ALGOLIA.INDEXES.POSITIONS);
    },
    mainQuery() {
      const queryRef = db
        .collection('positions')
        .where(
          'practiceUid',
          '==',
          this.$store.state.currentUser.activePractice.uid
        );
      return queryRef;
    }
  }
});
